import { useState } from "react";
import styles from "./index.module.scss";
import { AddVideosLegacy } from "components/add-videos/AddVideosLegacy";
import { usePageHeader } from "hooks/usePageHeader";
import { CustomHeaderState } from "store/header/types";

export const VideoLibraryPage = () => {
    const [headerData, setHeaderData] = useState<CustomHeaderState>(
        {} as CustomHeaderState
    );

    usePageHeader(headerData);

    return (
        <div className={styles["video-library-page"]}>
            <AddVideosLegacy
                isVideoLibrary={true}
                setHeaderData={setHeaderData}
            />
        </div>
    );
};
