import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import Lock from "assets/icons/lock.svg?react";
import { useCallback } from "react";
import { Banner } from "components/banners/Banner";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    Broadcast,
    BroadcastResponse,
    BroadcastStatus,
    CloudflareVideo,
    CreatorProductEntitlement,
    DeviceType,
    OrdinalRank,
    PlayerDetailsResponse,
    PlayerResponse,
    PlaylistItemResponse,
    VideoPlayerAspectRatio,
    VideoPlayerDefaultInteractiveTab,
    VideoPlayerEmbeddedDisplay,
    VideoPlayerIdleState,
    VideoPlayerPlaylistBroadcast
} from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { usePageHeader } from "hooks/usePageHeader";
import styles from "./index.module.scss";
import { CreateOrUpdateSwitcherPlayerModal } from "components/modal/CreateOrUpdateSwitcherPlayerModal";
import { DeleteModal } from "components/modal/DeleteModal";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { InlineButton } from "components/buttons/InlineButton";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";
import { openExternalUrlInNewTab } from "helpers/navigation";
import { CollectionLinkList } from "./CollectionLinkList";
import { Tabs } from "components/tabs/Tabs";
import { CopyEmbedCode } from "views/page-content/CatalogPage/CopyEmbedCode/CopyEmbedCode";
import { ShareLink } from "../ShareLink/ShareLink";
import { sortCloudflareVideosAsc } from "helpers/cloudVideosHelpers";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { AddVideosModal } from "components/modal/AddVideosModal";
import rollbar from "helpers/rollbar";
import { BroadcastDetails } from "components/entity-details/BroadcastDetails";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from "react-beautiful-dnd";
import { Button } from "components/buttons/Button";
import { useParams } from "hooks/useParams";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { AttentionModal } from "components/modal/AttentionModal";
import { useNavigate } from "react-router-dom";
import { Spinner } from "components/spinners/Spinner";
import { SkeletonTextLoader } from "components/skeleton/SkeletonTextLoader";
import { useUserStripeData } from "hooks/useUserStripeData";

const micrositeUrl = import.meta.env.VITE_SWITCHER_PLAYER_URL;

export interface OrderedBroadcast {
    broadcast: BroadcastResponse;
    playlistBroadcast: PlaylistItemResponse;
    videos: CloudflareVideo[];
    meta: OrderedBroadcastMeta;
}

interface OrderedBroadcastMeta extends VideoPlayerPlaylistBroadcast {
    ProductEntitlements?: CreatorProductEntitlement[];
}

enum CollectionTabs {
    Settings = "settings",
    Share = "share",
    Broadcasts = "broadcasts"
}

export const CollectionPage: React.FC = () => {
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    useRedirectIfDisallowed(() => !videoPlayerDisabled, "/collections");
    const { t } = useTranslation("collection-page");
    const { isMobile } = useIsMobile();
    const { isTablet } = useIsTablet();

    const {
        accountData: { gatedContentStatus }
    } = useUserStripeData({
        includeProducts: true,
        requestImmediately: true
    });

    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [deletePlayerModalOpen, setDeletePlayerModalOpen] =
        useState<boolean>(false);
    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const [videoPlayer, setVideoPlayer] = useState<PlayerDetailsResponse>();
    const [collectionWithEntitlements, setCollectionWithEntitlements] =
        useState<PlayerResponse>();
    const [isAfterInitialLoad, setIsAfterInitialLoad] =
        useState<boolean>(false);
    const [selectedBroadcasts, setSelectedBroadcasts] = useState<
        OrderedBroadcast[]
    >([]);
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<CollectionTabs>(
        CollectionTabs.Broadcasts
    );

    /** used to create playlist if one doesn't already exist */
    const {
        dispatchApiRequest: postVideoPlayerPlaylist,
        loading: loadingPostVideoPlayerPlaylist
    } = useSwitcherClient(
        (client) => client.videoPlayerPlaylist_PostVideoPlayerPlaylist,
        {
            requestImmediately: false
        }
    );

    /** add videos to playlist */
    const {
        dispatchApiRequest: postVideoPlayerPlaylistBroadcastsByIds,
        loading: loadingPostVideoPlayerPlaylistBroadcastsByIds
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcastsByIds,
        {
            requestImmediately: false
        }
    );

    /** reorder playlist (in drag and drop)*/
    const {
        dispatchApiRequest: putVideoPlayerPlaylistBroadcasts2,
        loading: loadingPutBroadcasts
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_PutVideoPlayerPlaylistBroadcasts2,
        {
            requestImmediately: false
        }
    );

    /** delete video */
    const {
        dispatchApiRequest: deleteVideoPlayerPlaylistBroadcasts,
        loading: loadingDeleteBroadcasts
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_DeleteVideoPlayerPlaylistBroadcasts,
        {
            requestImmediately: false,
            onSuccess: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("collection-page:video-delete-success")
                    })
                );
                dispatch(closeCurrentModal());
            }
        }
    );

    /** update custom slug */
    const { dispatchApiRequest: collectionUpdateCustomSlug } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PutVideoPlayer,
            {
                onSuccess: async (data) => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                `collection-page:custom-slug-update-success`
                            )
                        })
                    );
                    /** set the local state so that the UI changes without a refetch */
                    setVideoPlayer({
                        ...videoPlayer,
                        CustomSlug: data.CustomSlug
                    });
                },
                onError: () => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                `collection-page:custom-slug-update-error`
                            )
                        })
                    );
                }
            }
        );

    /** get the collections and their entitlements on load and when playlist is edited */
    const {
        data: videoPlayerEntitlement,
        dispatchApiRequest: fetchVideoPlayerEntitlements,
        loading
    } = useSwitcherClient((client) => client.videoPlayersV2_GetVideoPlayers, {
        args: [[videoPlayerId.toString()]],
        requestImmediately: true,
        onSuccess: (data) => {
            setIsAfterInitialLoad(true);
            const broadcastResponseMap =
                data?.Players?.[0]?.Playlists?.[0]?.Items?.reduce(
                    (memo, currentBroadcast) => ({
                        ...memo,
                        [currentBroadcast?.Details?.BroadcastId]:
                            currentBroadcast
                    }),
                    {} as PlaylistItemResponse
                );
            // find collection with entitlements and set var for use in file
            const _collectionInfoWithEntitlements = exists(videoPlayerId)
                ? data?.Players?.find((vp) => vp.Details?.Id === videoPlayerId)
                : data.Players?.[0];
            setCollectionWithEntitlements(_collectionInfoWithEntitlements);
            setVideoPlayer(_collectionInfoWithEntitlements?.Details);

            const orderedBroadcasts: OrderedBroadcast[] =
                data?.Players?.[0]?.Playlists?.[0]?.Items?.filter(
                    (broadcastWithVideos) =>
                        !!broadcastResponseMap[
                            broadcastWithVideos.Broadcast?.Details?.Id
                        ]
                )
                    ?.map((broadcastWithVideos) => {
                        const sortedVideos = sortCloudflareVideosAsc(
                            broadcastWithVideos.Broadcast?.Videos?.result
                        ) as CloudflareVideo[];

                        return {
                            broadcast: broadcastWithVideos.Broadcast,
                            playlistBroadcast: broadcastWithVideos,
                            videos: sortedVideos,
                            meta: {
                                ProductEntitlements:
                                    broadcastResponseMap[
                                        broadcastWithVideos?.Broadcast?.Details
                                            ?.Id
                                    ].EntitlementProducts
                            } as OrderedBroadcastMeta
                        };
                    })
                    .reduce((memo, currentBroadcast) => {
                        return memo.find(
                            (currentMemoBroadcast) =>
                                currentMemoBroadcast?.broadcast?.Details?.Id ===
                                currentBroadcast?.broadcast?.Details?.Id
                        )
                            ? memo
                            : [...memo, currentBroadcast];
                    }, [] as OrderedBroadcast[]);

            setSelectedBroadcasts(orderedBroadcasts);
            setFirstLoading(false);
        },
        onError: () => {
            setFirstLoading(false);
        }
    });

    const playerName = useMemo(() => {
        if (!videoPlayer) return;
        return videoPlayer?.Name
            ? videoPlayer?.Name
            : t("players:untitled-player");
    }, [t, videoPlayer]);

    const { dispatchApiRequest: deletePlayer } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_DeleteVideoPlayer
    );

    const handleDeleteSuccess = useCallback(() => {
        deletePlayer([videoPlayer?.Id, user?.userInfo?.ProjectId])
            .then(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("players:messages:delete-success")
                    })
                );

                navigate("/switcher-players");
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("players:errors:delete-error")
                    })
                );
            })
            .finally(() => setDeletePlayerModalOpen(false));
    }, [deletePlayer, videoPlayer, user, dispatch, t, navigate]);

    const handleAddVideos = useCallback(
        async (broadcastIds: string[]) => {
            try {
                let playlistId =
                    videoPlayerEntitlement?.Players?.[0]?.Playlists?.[0]
                        ?.Details?.Id;
                // create a default playlist if there isn't one already
                if (!playlistId) {
                    const res = await postVideoPlayerPlaylist([
                        {
                            VideoPlayerId: videoPlayerId,
                            IsDefault: true,
                            Title: "Default Playlist"
                        }
                    ]);
                    playlistId = res.Id;
                }

                await postVideoPlayerPlaylistBroadcastsByIds([
                    playlistId,
                    broadcastIds.reverse()
                ]);

                await fetchVideoPlayerEntitlements();
            } catch (e) {
                rollbar.error("Error adding videos to playlist", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:playlist-add-videos-error")
                    })
                );
            }
        },
        [
            videoPlayerEntitlement,
            t,
            postVideoPlayerPlaylistBroadcastsByIds,
            fetchVideoPlayerEntitlements,
            dispatch,
            postVideoPlayerPlaylist,
            videoPlayerId
        ]
    );

    const onAddVideosModalClosed = useCallback(() => {
        fetchVideoPlayerEntitlements(null);
    }, [fetchVideoPlayerEntitlements]);

    const closeModal = useCallback(() => {
        dispatch(closeCurrentModal());
    }, [dispatch]);

    const handleOpenModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.AddVideosModal,
                type: Modals.AddVideosModal,
                component: (
                    <AddVideosModal
                        playerId={videoPlayerId}
                        isMultiple={true}
                        allowAdditional={true}
                        isOpen
                        setIsOpen={closeModal}
                        onSelect={handleAddVideos}
                        previouslySelectedBroadcastIds={
                            !!selectedBroadcasts?.length
                                ? selectedBroadcasts.map(
                                      (sb) => sb.broadcast?.Details?.Id
                                  )
                                : []
                        }
                        onClose={onAddVideosModalClosed}
                        useAddVideosLegacy={true}
                    />
                )
            })
        );
    }, [
        closeModal,
        dispatch,
        handleAddVideos,
        onAddVideosModalClosed,
        videoPlayerId,
        selectedBroadcasts
    ]);

    const isSaving = useMemo(() => {
        return (
            isAfterInitialLoad &&
            (loadingPostVideoPlayerPlaylistBroadcastsByIds ||
                loading ||
                loadingPostVideoPlayerPlaylist ||
                loadingDeleteBroadcasts ||
                loadingPutBroadcasts)
        );
    }, [
        loadingPostVideoPlayerPlaylistBroadcastsByIds,
        isAfterInitialLoad,
        loading,
        loadingPostVideoPlayerPlaylist,
        loadingDeleteBroadcasts,
        loadingPutBroadcasts
    ]);

    usePageHeader({
        title: `${t("collection-page:page-title")}`,
        autoSave: isSaving,
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings")
        ],
        shouldHeaderWrap: false,
        subTitle: (
            <p className={styles["collection-details"]}>
                {firstLoading ? (
                    <SkeletonTextLoader />
                ) : (
                    <>
                        <strong>{playerName}</strong>
                        {videoPlayer?.Description && (
                            <>
                                {": "}
                                {videoPlayer?.Description}
                            </>
                        )}
                        <InlineButton
                            variant="tertiary"
                            onClick={() => setModalOpen(true)}
                        >
                            {t("collections-page:edit")}
                        </InlineButton>
                    </>
                )}
            </p>
        )
    });

    const handleUpdateCustomSlug = useCallback(
        (customSlug: string) => {
            collectionUpdateCustomSlug([
                videoPlayer?.Id,
                {
                    VideoPlayer: {
                        ...videoPlayer,
                        CustomSlug: customSlug,
                        // populates values that differ between PlayerDetailsResponse and VideoPlayer types
                        IdleState:
                            videoPlayer.IdleState as any as VideoPlayerIdleState,
                        DefaultInteractiveTab:
                            videoPlayer.DefaultInteractiveTab as any as VideoPlayerDefaultInteractiveTab,
                        AspectRatio:
                            videoPlayer.AspectRatio as any as VideoPlayerAspectRatio,
                        EmbeddedDisplay:
                            videoPlayer.EmbeddedDisplay as any as VideoPlayerEmbeddedDisplay
                    }
                },
                user?.userInfo?.ProjectId
            ]);
        },
        [collectionUpdateCustomSlug, user, videoPlayer]
    );

    const handlePreviewClick = useCallback(
        (type: "desktop" | "tablet" | "mobile") => {
            switch (type) {
                case "desktop":
                    openExternalUrlInNewTab(
                        `${micrositeUrl}/watch?p=${videoPlayer.Id}`
                    );
                    break;
                case "tablet":
                    if (isTablet) {
                        openExternalUrlInNewTab(
                            `${micrositeUrl}/watch?p=${videoPlayer.Id}`
                        );
                    } else {
                        openExternalUrlInNewTab(
                            `/device-preview?device=${DeviceType.IPad}&videoPlayerId=${videoPlayer.Id}&embeddedDisplay=${videoPlayer.EmbeddedDisplay}&aspectRatio=${videoPlayer.AspectRatio}`
                        );
                    }
                    break;
                case "mobile":
                    if (isMobile) {
                        openExternalUrlInNewTab(
                            `${micrositeUrl}/watch?p=${videoPlayer.Id}`
                        );
                    } else {
                        openExternalUrlInNewTab(
                            `/device-preview?device=${DeviceType.IPhone}&videoPlayerId=${videoPlayer.Id}&embeddedDisplay=${videoPlayer.EmbeddedDisplay}&aspectRatio=${videoPlayer.AspectRatio}`
                        );
                    }
                    break;
                default:
                    openExternalUrlInNewTab(
                        `${micrositeUrl}/watch?p=${videoPlayer.Id}`
                    );
            }
        },
        [isTablet, isMobile, videoPlayer]
    );

    const settings = useMemo(() => {
        return (
            <div className={styles["link-list"]}>
                <CollectionLinkList
                    hasVideoPlayerAccess={hasVideoPlayerAccess}
                    videoPlayer={videoPlayer}
                    t={t}
                    handlePreview={handlePreviewClick}
                />
            </div>
        );
    }, [handlePreviewClick, videoPlayer, hasVideoPlayerAccess, t]);

    const collectionShareLink = useMemo(
        () =>
            !!videoPlayer?.CustomSlug
                ? `${import.meta.env.VITE_SWITCHER_PLAYER_URL}/${
                      videoPlayer?.CustomSlug
                  }`
                : `${import.meta.env.VITE_SWITCHER_PLAYER_URL}/watch?p=${
                      videoPlayer?.Id
                  }`,
        [videoPlayer]
    );
    const share = useMemo(() => {
        return (
            <>
                <CopyEmbedCode entityId={videoPlayerId} variant="collection" />
                <ShareLink
                    currentSlug={videoPlayer?.CustomSlug}
                    shareLink={collectionShareLink}
                    variant="collection"
                    updateCustomSlug={handleUpdateCustomSlug}
                />
            </>
        );
    }, [
        handleUpdateCustomSlug,
        videoPlayerId,
        videoPlayer?.CustomSlug,
        collectionShareLink
    ]);

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            if (!result?.destination) return;
            if (result.reason === "DROP") {
                const targetBroadcast = selectedBroadcasts.find(
                    (b) =>
                        b.playlistBroadcast?.Details?.BroadcastId ===
                        result?.draggableId
                );
                const newSelectedBroadcasts = [...selectedBroadcasts];
                newSelectedBroadcasts.splice(result.source.index, 1);
                newSelectedBroadcasts.splice(
                    result.destination.index,
                    0,
                    targetBroadcast
                );

                const destinationPreviousBroadcastRank =
                    newSelectedBroadcasts[result.destination.index - 1]
                        ?.playlistBroadcast?.Details?.Rank;
                const destinationNextBroadcastRank =
                    newSelectedBroadcasts[result.destination.index + 1]
                        ?.playlistBroadcast?.Details?.Rank;

                setSelectedBroadcasts(newSelectedBroadcasts);

                try {
                    await putVideoPlayerPlaylistBroadcasts2([
                        targetBroadcast?.playlistBroadcast?.Details
                            ?.VideoPlayerPlaylistId,
                        {
                            PlaylistBroadcast: {
                                Id: targetBroadcast?.playlistBroadcast?.Details
                                    ?.Id,
                                VideoPlayerPlaylistId:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.VideoPlayerPlaylistId,
                                BroadcastId:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.BroadcastId,
                                OrdinalRank: {
                                    Ordinal:
                                        targetBroadcast?.playlistBroadcast
                                            ?.Details?.Ordinal,
                                    Rank: targetBroadcast?.playlistBroadcast
                                        ?.Details?.Ordinal
                                } as OrdinalRank,
                                CreatedAt:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.CreatedAt
                            },
                            PreviousOrdinal: destinationPreviousBroadcastRank,
                            NextOrdinal: destinationNextBroadcastRank
                        }
                    ]);

                    await fetchVideoPlayerEntitlements();
                } catch (e) {
                    rollbar.error("Error reordering playlist", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("messages:failed-to-reorder-playlist")
                        })
                    );
                }
            }
        },
        [
            selectedBroadcasts,
            putVideoPlayerPlaylistBroadcasts2,
            fetchVideoPlayerEntitlements,
            dispatch,
            t
        ]
    );

    const handleDeleteVideo = useCallback(
        async (video: OrderedBroadcast) => {
            const deleteVideo = async (video: OrderedBroadcast) => {
                try {
                    await deleteVideoPlayerPlaylistBroadcasts([
                        video?.playlistBroadcast?.Details
                            ?.VideoPlayerPlaylistId,
                        [video]?.map((btd) => {
                            return {
                                Id: btd?.playlistBroadcast?.Details?.Id,
                                VideoPlayerPlaylistId:
                                    btd?.playlistBroadcast?.Details
                                        ?.VideoPlayerPlaylistId,
                                BroadcastId:
                                    btd?.playlistBroadcast?.Details
                                        ?.BroadcastId,
                                OrdinalRank: {
                                    Ordinal:
                                        btd?.playlistBroadcast?.Details
                                            ?.Ordinal,
                                    Rank: btd?.playlistBroadcast?.Details?.Rank
                                } as OrdinalRank,
                                CreatedAt:
                                    btd?.playlistBroadcast?.Details?.CreatedAt
                            };
                        })
                    ]);
                    await fetchVideoPlayerEntitlements();
                } catch (e) {
                    rollbar.error("Error removing video from playlist", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:playlist-delete-video-error")
                        })
                    );
                }
            };

            const withEntitlement =
                collectionWithEntitlements?.Entitlements?.ProductEntitlements;

            if (withEntitlement.some((b) => b)) {
                dispatch(
                    setActiveModal({
                        id: Modals.AttentionModal,
                        type: Modals.AttentionModal,
                        component: (
                            <AttentionModal
                                isOpen
                                handleContinue={async () => {
                                    deleteVideo(video);
                                }}
                                setIsOpen={closeModal}
                                handleCancel={closeModal}
                                continueText={t(
                                    "messages:confirm-broadcast-with-pass-delete-cta"
                                )}
                            >
                                <span>
                                    {t(
                                        "messages:confirm-broadcast-with-pass-delete-message"
                                    )}
                                </span>
                            </AttentionModal>
                        )
                    })
                );
            } else {
                deleteVideo(video);
            }
        },
        [
            deleteVideoPlayerPlaylistBroadcasts,
            fetchVideoPlayerEntitlements,
            dispatch,
            t,
            closeModal,
            collectionWithEntitlements?.Entitlements?.ProductEntitlements
        ]
    );

    const playlist = useMemo(
        () => (
            <>
                <div
                    className={`${styles["add-video-button"]} ${styles["header-button"]}`}
                >
                    <Button type="primary" onClick={handleOpenModal}>
                        {t("playlist-page:buttons:add-videos")}
                    </Button>
                </div>

                <div
                    className={`${styles["playlist-container"]} ${
                        loading ? styles["reloading-inactive"] : ""
                    }`}
                >
                    {!!selectedBroadcasts?.length && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="broadcasts">
                                {(provided) => (
                                    <div
                                        className={
                                            styles[
                                                "broadcasts-droppable-container"
                                            ]
                                        }
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {selectedBroadcasts?.map(
                                            (video, index) => (
                                                <Draggable
                                                    draggableId={
                                                        video?.playlistBroadcast
                                                            ?.Details
                                                            ?.BroadcastId
                                                    }
                                                    key={
                                                        video?.playlistBroadcast
                                                            ?.Details
                                                            ?.BroadcastId
                                                    }
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            className={`${styles["broadcast-item"]}`}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                        >
                                                            <BroadcastDetails
                                                                video={
                                                                    video
                                                                        ?.playlistBroadcast
                                                                        ?.Broadcast
                                                                        ?.Videos
                                                                        ?.result?.[0]
                                                                }
                                                                collectionId={
                                                                    videoPlayerId
                                                                }
                                                                broadcast={
                                                                    {
                                                                        ...video
                                                                            ?.playlistBroadcast
                                                                            ?.Broadcast
                                                                            ?.Details,
                                                                        ThumbnailAsset:
                                                                            {
                                                                                Id: video
                                                                                    ?.playlistBroadcast
                                                                                    ?.Broadcast
                                                                                    ?.Details
                                                                                    ?.ThumbnailAssetId,
                                                                                SignedUrl:
                                                                                    video
                                                                                        ?.playlistBroadcast
                                                                                        ?.Broadcast
                                                                                        ?.Thumbnail
                                                                                        ?.Url
                                                                            },
                                                                        Categories:
                                                                            video.playlistBroadcast?.Broadcast?.Categories?.map(
                                                                                (
                                                                                    c
                                                                                ) =>
                                                                                    c.Category
                                                                            ),
                                                                        // map effectively converts CategoryResponse[] to Category[]
                                                                        // for use in the CategoryBadge component
                                                                        BroadcastStatus:
                                                                            video
                                                                                ?.playlistBroadcast
                                                                                ?.Broadcast
                                                                                ?.Details
                                                                                ?.BroadcastStatus as unknown as BroadcastStatus
                                                                    } as Broadcast
                                                                }
                                                                metrics={
                                                                    video
                                                                        ?.playlistBroadcast
                                                                        ?.Broadcast
                                                                        ?.MetricsSummary
                                                                }
                                                                playlistBroadcast={
                                                                    video
                                                                        ?.playlistBroadcast
                                                                        ?.Details
                                                                }
                                                                entitlements={
                                                                    video
                                                                        ?.playlistBroadcast
                                                                        ?.Entitlements
                                                                        ?.ProductEntitlements
                                                                }
                                                                badges
                                                                gatedContentStatus={
                                                                    gatedContentStatus
                                                                }
                                                                showEdit
                                                                onBroadcastUpdate={
                                                                    fetchVideoPlayerEntitlements
                                                                }
                                                                handleDeleteBroadcast={() =>
                                                                    handleDeleteVideo(
                                                                        video
                                                                    )
                                                                }
                                                                isDraggable={
                                                                    true
                                                                }
                                                                location={
                                                                    "player-playlist"
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        )}
                                        {
                                            provided.placeholder as React.ReactNode
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>
            </>
        ),
        [
            fetchVideoPlayerEntitlements,
            gatedContentStatus,
            handleDeleteVideo,
            handleDragEnd,
            handleOpenModal,
            selectedBroadcasts,
            t,
            videoPlayerId,
            loading
        ]
    );

    if (firstLoading)
        return (
            <div className={styles["loading"]}>
                <Spinner size={128} />
            </div>
        );

    return (
        <>
            {!hasVideoPlayerAccess && (
                <div className="row">
                    <div className="col-lg-12">
                        <Banner
                            header={t("collection-page:preview-banner")}
                            cssClass="preview"
                            icon={<Lock />}
                        />
                    </div>
                </div>
            )}
            <CreateOrUpdateSwitcherPlayerModal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                type="update"
                onSubmit={() => {
                    fetchVideoPlayerEntitlements();
                }}
                videoPlayer={collectionWithEntitlements}
            />
            <DeleteModal
                isOpen={deletePlayerModalOpen}
                setIsOpen={setDeletePlayerModalOpen}
                onSubmit={handleDeleteSuccess}
                titleText={t("collections:messages:delete-player")}
                subMsg={t("collections:messages:no-back")}
            />
            <div className="row">
                <div className="col-md-8">
                    <div className={styles["tab-view"]}>
                        {isMobile && (
                            <Tabs<CollectionTabs>
                                tabs={[
                                    {
                                        id: CollectionTabs.Broadcasts,
                                        label: t("collection-page:videos"),
                                        component: playlist
                                    },
                                    {
                                        id: CollectionTabs.Settings,
                                        label: t("collection-page:settings"),
                                        component: settings
                                    },
                                    {
                                        id: CollectionTabs.Share,
                                        label: t("collection-page:share"),
                                        component: share
                                    }
                                ]}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        )}
                    </div>
                    {!isMobile && playlist}
                </div>
                <div className="col-md-4">
                    {!isMobile && settings}

                    {!isMobile && share}
                </div>
            </div>
        </>
    );
};
